<template>
  <div class="page page-home">
    <PagesCommonHero
      :hero="{
        id: home.hero.id,
        overlayFullVideo: home.hero.overlayFullVideo,
        previewVideo: home.hero.previewVideo,
        whiteText: home.hero.whiteText,
        title: home.hero.title,
        text: home.hero.text,
      }"
      v-if="home.hero"
    />
    <div
      class="page__wrap p-top-xxxl-1 p-bottom-xxxl-1"
      data-animate="preloader-opacity"
    >
      <!-- Home Intro Text-->
      <section class="row-1" v-if="home.homeIntroText">
        <WidgetIntroTextBlock :text="home.homeIntroText" />
      </section>

      <!-- Expertise -->
      <section class="row-1 m-top-xxxl-1">
        <WidgetContentStickyExsud
          :preTitle="home.preTitleExpertise"
          :title="home.titleExpertise"
        >
          <template v-slot:content>
            <!--<div
              class="page-home-grid-expertise --5_4"
              v-staggered-show="'article.expertise-block-text-inside'"
            >
              <ExpertiseBlockTextInside
                v-for="expertise in expertises"
                :key="expertise.id"
                :item="expertise"
                :link-route="'expertise-slug'"
              />
            </div>-->
              <div
              class="page-home-grid-expertise-container"
              v-staggered-show="'article.home-block-text-inside'"
            >
              <PagesHomeBlockTextInside 
              v-for="block in home.expertiseGrid"
              :key="block.id"
              :item="block"/>

            </div>
          </template>
        </WidgetContentStickyExsud>
      </section>

      <!-- Manifesto -->
      <section class="row-1 m-top-xxxl-1">
        <WidgetContentStickyExsud
          :preTitle="home.preTitleManifesto"
          :title="home.titleManifesto"
        >
          <template v-slot:content>
            <PagesHomeBLieveBanner :content="home" />
          </template>
        </WidgetContentStickyExsud>
      </section>

      <!-- Projects -->
      <section class="row-1 m-top-xxxl-1">
        <WidgetContentStickyExsud
          :preTitle="home.preTitleProjects"
          :title="home.titleProjects"
        >
          <template v-slot:content>
            <PagesCommonGridParallax
              v-for="(projects, index) in home.homeProjects"
              :key="index"
              :items="projects?.project"
              :reverse="index % 2 !== 0"
              :class="{
                'm-top-xl-1': index > 0,
                '--is-full-width': projects.fullWidth,
                '--small-height': projects.smallHeight,
              }"
            />
          </template>
        </WidgetContentStickyExsud>

        <div class="is-text-center m-top-l-1">
          <nuxt-link
            :to="
              localePath({
                name: 'projects',
              })
            "
            class="button-round-1 is-big"
            ><span>{{ $t('home.projects.allProjects') }}</span>
          </nuxt-link>
        </div>
      </section>

      <!-- Technology List -->
      <section
        class="row-1 m-top-xxxl-1"
        v-if="home.homeTechnologyList?.length"
      >
        <PagesHomeTechnologyList
          :pre-title="home.preTitleTechnology"
          :title="home.homeTechnologyTitle"
          :technologies="home.homeTechnologyList"
        />
      </section>

      <!-- Blog Section -->
      <section class="row-1 m-top-xxxl-1" v-if="blogs?.length">
        <PagesHomeBlogList
          :pre-title="home.preTitleBlog"
          :title="home.titleBlog"
          :items="home?.articles?.length ? home.articles : blogs"
        />
        <div class="is-text-center m-top-l-1">
          <nuxt-link
            :to="
              localePath({
                name: 'blog',
              })
            "
            class="button-round-1 is-big"
            ><span>{{ $t('home.blog.allBlogs') }}</span>
          </nuxt-link>
        </div>
      </section>
      <template v-if="home?.ctaType !== 'Nascondi'">
        <div class="row-1">
          <template
            v-if="home?.ctaType === 'Custom' && home.contactBannerBlock"
          >
            <WidgetBannerCta
              :banner="home.contactBannerBlock"
              class="m-top-xxl-1"
            />
          </template>
          <template v-else>
            <ContactBanner
              :type="home?.ctaType === 'Contact Banner 2' ? 2 : 1"
              class="m-top-xxl-1"
            />
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { gsap } from 'gsap'
import queries from '@/graphql/dato/queries/index.js'

import pageTransition from '@/assets/js/transitions/helper.js'

definePageMeta({
  layout: 'default',
  pageTransition,
})

const { locale } = useI18n()

const query = queries.home.getHome
const variables = computed(() => ({
  locale: locale.value,
}))

const staggeredBlocksShown = ref(false)
const duration = 0.6
const durationFast = 0.4
const ease = 'power2.inOut'

const { data } = await useGraphql(query, variables)

if (!data.value?.home) {
  throw createError({ statusCode: 404 })
}

const home = computed(() => data.value?.home)
const expertises = computed(() => data.value?.allExpertises)
const blogs = computed(() => data.value?.allArticles)

const seo = computed(() => data.value?.home?.seo || {})

const vStaggeredShow = {
  created(el, binding, vnode, prevVnode) {
    const options = {
      threshold: [0, 1],
    }
    const targetElements = binding.value
    if (targetElements) {
      const observer = new IntersectionObserver(
        (entries, observer) =>
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (!staggeredBlocksShown.value) {
                const blocks = el?.querySelectorAll(targetElements)
                gsap.to(blocks, {
                  autoAlpha: 1,
                  y: '0%',
                  duration,
                  ease,
                  stagger: 0.1,
                })
                staggeredBlocksShown.value = true
              }
            }
          }),
        options
      )
      observer.observe(el)
    }
  },
}
// Seo
useSeo({
  ...seo.value,
})

addTrackingZoho()
</script>
