<template>
  <article class="home-block-text-inside" ref="block">
    <nuxt-link
      :to="item.linkUrl && item.linkUrl !== '' ?item.linkUrl : localePath(getLink(item))"
      class="home-block-text-inside__wrap"
      :class="{
        'is-hover': isHover,
      }"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    >
      <div class="home-block-text-inside__wrap-media">
        
        <WidgetPicture
          v-if="item.image || (item.reference && item.reference?.imagePreviewListing)"
          :image="{ custom: item.image?.responsiveImage ?  item.image?.responsiveImage : item.reference?.imagePreviewListing?.responsiveImage }"
          :lazyLoad="true"
          :alt="getAltImage(item)"
        />
        <div
          v-if="hasPreview"
          class="home-block-text-inside__video"
          data-animate="home-block-video-preview"
        >
          <WidgetVideo
            class="is-cover"
            :videoUrl="item.videoPreview ? item.videoPreview : item.reference.linkPreviewVideoListing"
            :loop="true"
            :forcePlay="isHover"
            :autoplay="touch"
            :muted="true"
            :preload="'none'"
            :playInViewport="touch"
            @play="onViewportEnter"
            @pause="onViewportLeave"
          />
        </div>
        <div class="home-block-text-inside__content">
          <div v-if="item.title !== ''" class="home-block-text-inside__title title-l-1"  v-html="item.title"/>

          <h3 v-else-if="item.reference"
            class="home-block-text-inside__title title-l-1"
            v-html="item.reference.title"
          />
          <!--<div
          v-if="item.caption"
          class="home-block-text-inside__caption text-xs-1"
          v-html="item.caption"
        />-->
        </div>
      </div>
      <div
        v-if="(item.caption && item.caption !== '') || (item.reference && item.reference.caption)"
        class="home-block-text-inside__caption_outer text-xs-1"
        v-html="item.caption !== '' ? item.caption : item.reference.caption"
      />
    </nuxt-link>
  </article>
</template>

<script>
export default {
  name: 'ExpertiseBlockTextInside',
}
</script>

<script setup>
import { gsap } from 'gsap'

const props = defineProps({
  item: Object,
  linkRoute: String,
})

const block = ref(null)

const isHover = ref(false)
const touch = ref(false)

const duration = 0.6
const ease = 'power2.inOut'

const hasPreview = computed(() => props.item?.videoPreview !== '' || props.item?.reference?.linkPreviewVideoListing)
onMounted(() => {
  touch.value = 'ontouchstart' in window || !!navigator.maxTouchPoints
})

const onMouseEnter = () => {
  if (touch.value) return false

  const parent = block.value
  const video = parent?.querySelector(
    '[data-animate="home-block-video-preview"]'
  )

  isHover.value = true

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 1, duration, ease })
  }
}

const onMouseLeave = () => {
  if (touch.value) return false

  const parent = block.value
  const video = parent?.querySelector(
    '[data-animate="home-block-video-preview"]'
  )

  isHover.value = false

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 0, duration, ease })
  }
}
const onViewportEnter = () => {
  const parent = block.value
  const video = parent?.querySelector(
    '[data-animate="home-block-video-preview"]'
  )

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 1, duration, ease })
  }
}

const onViewportLeave = () => {
  const parent = block.value
  const video = parent?.querySelector(
    '[data-animate="home-block-video-preview"]'
  )

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 0, duration, ease })
  }
}

const getLink = (link) => {
  let linkPath
  if (link.linkUrl && link.linkUrl !== '') {
    linkPath = link.linkUrl
    return linkPath
  }
  if (link.reference) {
    if (link.reference.__typename == 'ExpertiseRecord') {
      linkPath = {
          name: 'expertise-slug',
          params: { slug: link.reference.slug },
        }
        return linkPath
    }
    if (link.reference.__typename == 'TechnologyRecord') {
      linkPath = {
          name: 'technology-slug',
          params: { slug: link.reference.slug },
        }
        return linkPath
    }
  }
}

const getAltImage = (item) => {
  if (item.image?.responsiveImage?.alt) {
    return item.image.responsiveImage.alt
  } 

  if (item.reference) {
    if (item.reference.imagePreviewListing?.responsiveImage?.alt) {
      return item.reference.imagePreviewListing.responsiveImage.alt
    }
    return item.reference.title
  }

  return item.title || ""
  
}
</script>

<style lang="scss">
@import './style.scss';
</style>
